import React, {forwardRef, useState} from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
import Section from "../section";
import {CONTAINER, H3, H5, LEADTEXT} from "../../styles/Global";
import { BREAKPOINTS } from "../../constants";
import IconWithText from "../IconWithText";
import { MdEmail } from "@react-icons/all-files/md/MdEmail";
import { MdPhoneIphone } from "@react-icons/all-files/md/MdPhoneIphone";
import { useMediaQuery } from 'react-responsive';
import { MessageField, TextField} from "../../styles/Forms";

const Contact = (props, ref) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINTS['md'] });
  const [state, setState] = useState({submitted: false})
  const url = `${process.env.GATSBY_API_URL}/webform_rest/submit`;

  const submitForm = async (event) => {
    event.preventDefault();

    const contactDetail = {
      webform_id: process.env.GATSBY_CONTACT_FORM_ID,
      name: state.name,
      email: state.email,
      message: state.message,
    };

    const result = await fetch(url, {
      body: JSON.stringify(contactDetail),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    });

    result.json().then((res) => {
      if (!result.ok) {
        console.log(result, res.error);

        let update = {...state, ...{submitted: false, error: res.error}};
        setState(update);
      } else {
        let update = {...state, ...{submitted: true, error: false}};
        console.log(update);
        setState(update);
      }
    });
  };

  const handleChange = (name, value) => {
    let update = {...state};
    update[name] = value;
    setState(update);
  }

  return (
    <Wrapper id="contact" {...props} ref={ref}>
      {state.error ? (
        <Error>
          <ErrorMessage>
            {state.error}
          </ErrorMessage>
        </Error>
      ) : null}
      {state.submitted ? (
        <Success>
          <SuccessMessage>
            Thank you for reaching out! We will contact you shortly.
          </SuccessMessage>
          <Sig>&mdash; JLTech</Sig>
        </Success>
      ) : null}
      <Container>
        <Content>
          <Row justify="space-between" gutter={{xs: 8, sm: 20, md: 30, lg: 64, xl: 256}}>
            <Col xs={24} sm={24} md={12} lg={12} xl={14}>
              <Title className="animate-in-slide-up">Let’s Chat.</Title>
              <Paragraph className="animate-in-slide-up">
                Are you ready to start your project? Maybe you have some
                questions? We’re here, let’s chat.
              </Paragraph>
              {!isMobile ? (
                <>
                  <IconWithText size={18} icon={<MdPhoneIphone />} className="animate-in-slide-up">
                    <Anchor href={`tel:${process.env.GATSBY_PHONE_NUMBER.replace(/\D/g, "")}`}>{process.env.GATSBY_PHONE_NUMBER}</Anchor>
                  </IconWithText>
                  <IconWithText size={18} icon={<MdEmail />} className="animate-in-slide-up">
                    <Anchor href={`mailto:${process.env.GATSBY_EMAIL}`}>{process.env.GATSBY_EMAIL}</Anchor>
                  </IconWithText>
                </>
              ) : null}
            </Col>
            <Col xs={22} sm={24} md={12} lg={12} xl={10}>
              <form onSubmit={submitForm}>
                <TextField
                  placeholder="Name"
                  bordered={false}
                  value={state.name}
                  onChange={(e) => handleChange('name', e.target.value)}
                />
                <TextField
                  placeholder="Email"
                  bordered={false}
                  value={state.email}
                  onChange={(e) => handleChange('email', e.target.value)}
                />
                <TextAreaSubmit>
                  <MessageField
                    placeholder="Type your message…"
                    bordered={false}
                    rows={isMobile ? 4 : 6}
                    value={state.message}
                    onChange={(e) => handleChange('message', e.target.value)}
                  />
                  <FormSubmit type="submit" onClick={submitForm}>
                    Submit
                  </FormSubmit>
                </TextAreaSubmit>
              </form>

              {isMobile ? (
                <>
                  <IconWithText size={18} icon={<MdPhoneIphone />}>
                    <Anchor href={`tel:${process.env.GATSBY_PHONE_NUMBER.replace(/\D/g, "")}`}>{process.env.GATSBY_PHONE_NUMBER}</Anchor>
                  </IconWithText>
                  <IconWithText size={18} icon={<MdEmail />}>
                    <Anchor href={`mailto:${process.env.GATSBY_EMAIL}`}>{process.env.GATSBY_EMAIL}</Anchor>
                  </IconWithText>
                </>
              ) : null}
            </Col>
          </Row>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default forwardRef(Contact);

const Wrapper = styled(Section)`
  background: rgba(65, 45, 219, 0.7);
  display: flex;
  flex-flow: column;
`;

const Success = styled.div`
  background: #ff3d00;
  color: #fff;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  margin-bottom: 50px;
  text-align: center;
`;

const SuccessMessage = styled.q`
  ${H5}
`;

const Sig = styled.figcaption`
  ${H5}
`;

const Error = styled.div`
  background: #ff3d00;
  color: #fff;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  margin-bottom: 50px;
  text-align: center;
`;

const ErrorMessage = styled.div`
  ${H5}
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

const Anchor = styled.a`
  color: #ffffff;
`

const Content = styled.div`
  width: 100%;
`;

const Title = styled.h2`
  ${H3}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  ${LEADTEXT}
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
`;

const TextAreaSubmit = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const FormSubmit = styled.button`
  writing-mode: vertical-rl;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff3d00;
  border: none;
  color: #ffff;
  box-sizing: border-box;
  text-decoration: none;
  margin-right: 0px;
  font: normal normal normal 22px/30px var(--unnamed-font-family-brandon-grotesque);
  padding: 10px;
  cursor: pointer;
  
  &&:hover,
  &&:focus {
    background-color: rgba(65, 45, 219, 1);
    color: #ffff;
  }
`;
