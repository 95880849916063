import React from "react";
import styled from "styled-components";
import {BREAKPOINTS} from "../constants";
import {HiOutlineArrowCircleRight} from "@react-icons/all-files/hi/HiOutlineArrowCircleRight";

const IconWithText = ({children, size, animation, delay, easing, icon, wrapperStyle, textStyle, iconStyle, ...props}) => {
  return (
    <Wrapper {...props}>
      <IconWrapper style={wrapperStyle} size={size ? size : 16}>
        <Icon style={iconStyle}>{icon ? icon : <HiOutlineArrowCircleRight />}</Icon>
      </IconWrapper>
      {children ? (
        <Text style={textStyle} size={size ? size : 16}>{children}</Text>
      ) : null}
    </Wrapper>
  );
};

export default IconWithText;

const Wrapper = styled.div`
  display:flex;
  align-items:center;
  justify-content:flex-start;
  padding-bottom: 10px;
`;

const Text = styled.span`
  color: #ffffff;
  line-height: 100%;
  font-size: ${props => `${props.size}px`};
  font-family: var(--unnamed-font-family-proxima-nova);
  
  @media (min-width: ${BREAKPOINTS['sm']}px) {
    font-size: ${props => `${props.size}px`};
  }

  @media (min-width: ${BREAKPOINTS['md']}px) {
    font-size: ${props => `${props.size}px`};
  }
  
  @media (min-width: ${BREAKPOINTS['lg']}px) {
    font-size: ${props => `${props.size}px`};
  }

  @media (min-width: ${BREAKPOINTS['xl']}px) {
    font-size: ${props => `${props.size}px`};
  }

  @media (min-width: ${BREAKPOINTS['xxl']}px) {
    font-size: ${props => `${props.size}px`};
  }

  @media (min-width: ${BREAKPOINTS['4k']}px) {
    font-size: ${props => `${props.size * 1.5}px`};
  }
`;

const IconWrapper = styled.div`
  width: ${props => `${props.size * 2}px`};
  height: ${props => `${props.size * 2}px`};
  line-height: ${props => `${props.size * 2}px`};
  margin-right: ${props => `${props.size * .5}px`};
  font-size: ${props => `${props.size}px`};
  border-radius: 50%;
  background-color: #ffffff;
  text-align: center;
  display: inline-block;

  @media (min-width: ${BREAKPOINTS['4k']}px) {
    width: ${props => `${props.size * 2.5}px`};
    height: ${props => `${props.size * 2.5}px`};
    line-height: ${props => `${props.size * 4}px`};
    margin-right: ${props => `${props.size * .5}px`};
    font-size: ${props => `${props.size * 1.5}px`};
  }
`;

const Icon = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  background: transparent;
  width: 100%;
  height: 100%;
  color: #ec3917
`;
