import ReactHtmlParser from "react-html-parser";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";

export const processImage = (node, field, media = 'field_media_image') => {
  let image = {};

  if (node.relationships && field && node.relationships[field]) {
    image = {...node.relationships[field].field_media_image, ...node.relationships[field].relationships[media].localFile.childImageSharp}
  } else if (node.field_media_image) {
    image = {...node.field_media_image, ...node.relationships.field_media_image.localFile.childImageSharp}
  }

  return image;
};

export const processComments = (entities) => {
  const hashTable = Object.create(null);
  entities.forEach(aData => hashTable[aData.drupal_id] = {...aData, children: []});
  const comments = [];
  entities.forEach(aData => {
    if (aData.relationships.pid) {
      hashTable[aData.relationships.pid.drupal_id].children.push(hashTable[aData.drupal_id]);
    }
    else {
      comments.push(hashTable[aData.drupal_id])
    }
  });
  return comments;
};

export const processFormattedText = (field, media) => {
  return new ReactHtmlParser(field.value, {
    transform: function transform(node) {
      if (media && node.name === 'drupal-media') {
        let uuid = node.attribs['data-entity-uuid'];

        return media.nodes.map((node, index) => {
          if (uuid && node.drupal_id === uuid) {
            const image = processImage(node);
            return (
              <GatsbyImage
                key={index}
                image={image.gatsbyImageData}
              />
            )
          }

          return undefined
        })
      }
    }
  });
}

export const getRelationship = (node, field) => {
  let relationship = null;

  if (node.relationships && field && node.relationships[field]) {
    relationship = node.relationships[field]
  }

  return relationship;
};

export const getTagString = (values) => {
  let string = '';

  for (let i = 0; i < values.length; i++) {
    let delimiter = i > 0 ? i === values.length - 1 ? ' & ' : ', ' : '';
    string += `${delimiter}${values[i].name}`;
  }

  return string;
}

export const getBackground = (node) => {
  let background = {};

  if (node.relationships && node.relationships.field_background_image) {
    const image = processImage(node, 'field_background_image');
    background = {...background, ...{image: image}};
  }

  if (node.field_background_color) {
    let color = hexToRgbA(node.field_background_color.color, node.field_background_color.opacity);
    background = {...background, ...{color: color}};
  }

  return background;
};

export const hexToRgbA = (hex, opacity) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x'+c.join('');
    return `rgba(${[(c>>16)&255, (c>>8)&255, c&255].join(',')}, ${opacity})`;
  }
}

export const getYoutubeId = (url) => {
  return url.match(/(?:https?:\/\/)?(?:www\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&]+)/)[1];
};
